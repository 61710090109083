/* ReviewComponent.css */

.comment-bubble {
  background-color: #f0f0f0; /* Background color of the speech bubble */
  border: 2px solid #ccc; /* Border color and width */
  border-radius: 10px; /* Rounded corners */
  padding: 10px; /* Padding inside the speech bubble */
  margin-top: 10px; /* Margin between the comment and other content */
  max-width: 80%; /* Maximum width of the speech bubble */
  position: relative; /* Relative positioning for ::before pseudo-element */
}

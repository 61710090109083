@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --avo-green: rgb(194, 216, 76);
  --light-grey: #efede7;
  --avo-brown: #800317;
  --light-black: #33353b;
  --black: #1f0f19;
  --avo-green: '#869b59';
  --avo-brown: '#6d4337';
}

.light-green {
  color: rgb(194, 216, 76);
}

.light-grey {
  color: #efede7;
}

.scarlet {
  color: #800317;
}

.light-black {
  color: #33353b;
}

.black {
  color: #1f0f19;
}

.scarlet-bg {
  background-color: var(--avo-brown);
}

.bg-image {
  position: relative;
}

svg:hover circle {
  stroke: var(--avo-brown);
}
.bg-image::before {
  content: '';
  background-image: url('../public/img/hairp4.jpg');
  /* Your background image URL */
  background-size: repeat;
  /* Adjust this as needed */
  opacity: 0.5;
  /* Adjust the opacity as needed (0.5 for 50% opacity) */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  /* Make sure the image is below the content */
  height: 100%;
}
.jumbotron-image {
  background-image: url('../public/img/jumbobgp.png');
}
.services-image {
  background-image: url('../public/img/servicesbg.png');
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 200px;
  transform: scaleX(-1);
}
.styles-image {
  background: url('../public/img/stylesbg.png') right top no-repeat,
    /* url('../public/img/stylesbg-03.jpeg') right bottom no-repeat, */
      url('../public/img/stylesbg-02.jpeg') left top no-repeat;
  /* url('../public/img/stylesbg-04.png') right top no-repeat; */
  background-size: 10%, 20%, 30%, 25%;
  padding: 15px;
}
.prevent-select {
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
